import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import withMobileDialog from "@material-ui/core/withMobileDialog";

const styles = theme => ({
  table: {
    borderCollapse: "collapse",
    margin: "0 auto",
    textAlign: "center",
    overflow: "auto"
  },
});

class TermsConditionAlly extends Component {
  render() {
    const { classes } = this.props;

    return (
      <div className="max-w-5xl w-full mx-auto">
        <div>
          <p className="text-center my-4">
            <b>TÉRMINOS Y CONDICIONES DEL ACUERDO CON ALIADOS ASISTENSI</b>
          </p>
        </div>
        <div className="text-justify">
          <div>
            <p className="my-4">
              Los presentes términos y condiciones definen y regulan la relación comercial
              entre las subsidiarias y afiliadas de <b>MAYTOWER S.L</b> y sus aliados y aliados
              maestros.
            </p>
          </div>
          <div>
            <p className="my-4">
              <b>PRIMERO: DEFINICIONES</b>
            </p>
          </div>

          <div>
            <p className="my-4">
              <b>A. MAYTOWER S.L</b> es una compañía constituida bajo las leyes del reino de España
              cuyas compañías subsidiarias se dedican a la comercialización de seguros, productos y
              servicios de salud. Todas las menciones de <b>ASISTENSI</b> en el presente acuerdo harán referencia
              a todas las compañías subsidiarias y afiliadas de <b>MAYTOWER S.L</b> que se dediquen a comercializar
              seguros, productos y servicios de salud bajo la marca <b>“asistensi”</b>.
            </p>

            <p className="my-4">
              <b>B. GENERAR LEADS</b>  ( <b>GENERACIÓN DE LEADS</b> o <b>GENERACIÓN DE UN LEAD</b>) es la dirección
              de personas a las plataformas digitales de <b>ASISTENSI</b> incluyendo su página web asistensi.com y sus
              aplicaciones móviles descargables en el Apple Store y Google Store. Esta actividad puede incluir,
              o no, la afiliación de dicha persona como usuario en la plataforma (la <b>AFILIACIÓN</b>) siempre y cuando
              la persona autorice al <b>ALIADO</b> o al <b>ALIADO MAESTRO</b> su <b>AFILIACIÓN</b> y le ceda al <b>ALIADO </b> o al
              <b> ALIADO MAESTRO  </b>los datos personales requeridos para esto. Ni la <b>AFILIACIÓN </b> ni la <b>GENERACIÓN DE LEAD </b> podrán incluir la
               intermediación en la suscripción o compra, o la asesoría para la suscripción o compra, de un producto
                o servicio comercializado por <b>ASISTENSI </b> salvo que el <b>ALIADO </b> o el <b>ALIADO MAESTRO </b> tenga las autorizaciones
                pertinentes por parte de los reguladores aplicables para ejercer dicha intermediación o asesoría.
            </p>

            <p className="my-4">
              <b>C. ALIADO MAESTRO</b> es una persona o sociedad que ha sido expresamente autorizado
              por <b>ASISTENSI</b> tanto para <b>(i) GENERAR LEADS</b> como para (ii) autorizar nuevos aliados (siendo estos un <b>ALIADO </b>
              y no un <b>ALIADO MAESTRO</b>). La autorización de un <b>ALIADO MAESTRO</b> no implica afiliación o asociación alguna con
              <b> ASISTENSI</b> ni relación laboral de ningún tipo con <b>ASISTENSI</b>.
            </p>

            <p className="my-4">
              <b>D. ALIADO</b> es una persona o sociedad que ha sido expresamente autorizado tanto por 
              <b> ASISTENSI</b> como por <b>ALIADO MAESTRO</b> para <b>GENERAR LEADS</b>. La autorización de un <b>ALIADO</b> no implica afiliación
              o asociación alguna con ASISTENSI ni relación laboral de ningún tipo con <b>ASISTENSI</b>.
            </p>

            <p className="my-4">
              <b>E. ACS</b> (o la <b>AFILIACIÓN CON SUSCRIPCIÓN</b>) hace referencia al acto de cuando una persona que,
              producto de una <b>GENERACIÓN DE LEAD</b> por parte de un <b>ALIADO</b> o <b>ALIADO MAESTRO</b> y la participación del <b>ALIADO </b> 
              o <b>ALIADO MAESTRO</b> en dicha <b>GENERACIÓN DE LEAD</b> sea atribuible y demostrable, haga una <b>AFILIACIÓN</b> y
               posteriormente contrate por cuenta propia y pague de manera efectiva un producto o servicio de <b>ASISTENSI </b> 
                a través de la página web asistensi.com o a través de sus aplicaciones móviles descargables en el Apple
                Store y Google Store.
            </p>

            <p className="my-4">
              <b>F. PPL</b> (o el <b>PAGO POR LEAD</b>) es la compensación económica que realizará <b>ASISTENSI</b> y a la que tendrá
              derecho el <b>ALIADO</b> o el <b>ALIADO MAESTRO</b> por cada nueva <b>ACS</b>. Los montos, fechas y condiciones de pago de esta se
              regirán por las exposiciones del presente acuerdo y sus anexos y notas aclaratorias emitidas por 
              <b> ASISTENSI</b>.
            </p>

            <p className="my-4">
              <b>G. PPR</b> (o el <b>PAGO POR RENOVACIÓN</b>) es la compensación económica que realizará <b>ASISTENSI</b> y a la que
              tendrá derecho el <b>ALIADO</b> o el <b>ALIADO MAESTRO</b> por cada <b>ACS</b> que, después de transcurrido un nuevo año póliza,
               es renovada por parte del cliente. Los montos, fechas y condiciones de pago de esta se regirán por las
               exposiciones del presente acuerdo y sus anexos y notas aclaratorias emitidas por <b> ASISTENSI</b>.
            </p>

            <p className="my-4">
              <b>H. CÓDIGO DE ALIADO</b> es un código alfanumérico único asignado por <b>ASISTENSI</b> a cada <b>ALIADO</b> o <b>ALIADO MAESTRO</b> 
               para facilitar el seguimiento de <b>ACS</b> atribuibles a cada <b>ALIADO</b> o <b>ALIADO MAESTRO</b>. En el caso del <b>ALIADO</b>, este <b>CÓDIGO
                DE ALIADO</b> a su vez estará también clasificado bajo el <b>ALIADO MAESTRO</b> que le autorizó o el que designe <b>ASISTENSI </b> 
                en caso de terminación de la condición de dicho <b>ALIADO MAESTRO</b>.
            </p>

            <p className="my-4">
              <b>I. CICLO DE PAGO</b> hace referencia al tiempo en el que <b>ASISTENSI</b> tarda en efectuar los desembolsos de los <b>PPL</b> y
              los <b>PPR</b> que estén pendiente de pago por un tiempo de hasta un máximo de 30 días calendarios a los <b>ALIADOS MAESTROS</b> y <b>ALIADOS</b> según los
               términos y condiciones del presente acuerdo. Por lo tanto, y con el objetivo de evitar dudas, <b>ASISTENSI</b> realizará un único desembolso
                al mes a cada <b>ALIADO MAESTRO</b> y <b>ALIADO</b> en el caso de que sea aplicable según los términos y condiciones del presente acuerdo de los <b>PPL </b>
                 y <b>PPR</b> que estén pendientes de pago.
            </p>

            <br />
            <p className="my-4">
              <b>SEGUNDO: CONDICIONES DEL PPL Y PPR</b>
            </p>


            <p className="my-4">
              <b>A. </b>Los montos del <b>PPL</b> para el <b>ALIADO MAESTRO</b> serán como se indica a continuación:
            </p>

            <ul>
              <li>
                <b>I.</b> Para cada nueva <b>ACS</b> que se haya realizado con el <b>CÓDIGO DE ALIADO</b> del <b>ALIADO MAESTRO</b>: un <b>20% </b>del equivalente
                anual del precio de la póliza de <b>ASISTENSI </b> neto de descuentos y de costo de financiación (esto último para las pólizas pagadas en modo mensual, trimestral o semestral).
              </li>
              <li>
                <b>II.</b> Para cada nueva <b>ACS</b> que se haya realizado con un <b>CÓDIGO DE ALIADO</b> de un <b>ALIADO</b> que esté clasificado bajo el <b>ALIADO MAESTRO</b>:
              </li>

              <li>
                <div className="m-t20 m-b20">
                  <table border="1px" className={`${classes.table} border border-gray-300`}>
                    <caption className="text-pink-500">PPL, como % del equivalente anual del precio de la póliza de ASISTENSI neto de descuentos y de costo de financiación (esto último para las pólizas pagadas en modo mensual, trimestral o semestral).</caption>
                    <tbody>
                      <tr className="border border-gray-300">
                        <td width="33%"> Número de nuevas ACS durante el mes calendario bajo el CODIGO DE ALIADO clasificado bajo el ALIADO MAESTRO</td>

                        <td width="30%">PPL</td>
                      </tr>

                      <tr className="border border-gray-300">
                        <td>Menos de 5 </td>
                        <td>10,00%</td>
                      </tr>

                      <tr className="border border-gray-300">
                        <td>Entre 6 y 9 </td>
                        <td>7,50%</td>
                      </tr>
                      <tr className="border border-gray-300">
                        <td>Más de 10 </td>
                        <td>5,00%</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </li>

            </ul>


            <p className="my-4">
              <b>B. </b>Los montos del <b>PPL</b> para el <b>ALIADO</b> serán como se indica a continuación:
            </p>

            <ul>
              <li>
                <div className="m-t20 m-b20">
                  <table border="1px" className={classes.table}>
                    <caption className="text-pink-500">PPL, como % del equivalente anual del precio de la póliza de ASISTENSI neto de descuentos y de costo de financiación (esto último para las pólizas pagadas en modo mensual, trimestral o semestral).</caption>
                    <tbody>
                      <tr className="border border-gray-300">
                        <td width="33%"> Número de nuevas ACS durante el mes calendario bajo el CODIGO DE ALIADO</td>

                        <td width="30%">PPL</td>
                      </tr>

                      <tr className="border border-gray-300">
                        <td>Menos de 5 </td>
                        <td>10,00%</td>
                      </tr>

                      <tr className="border border-gray-300">
                        <td>Entre 6 y 9 </td>
                        <td>12,50%</td>
                      </tr>
                      <tr className="border border-gray-300">
                        <td>Más de 10 </td>
                        <td>15,00%</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </li>

            </ul>

            <p className="my-4">
              <b>C. </b>Tanto el <b>ALIADO</b> como el <b>ALIADO MAESTRO</b> tendrán la posibilidad de ofrecer al cliente un <b>5%</b> de
              descuento del precio equivalente anual de la póliza de seguro de <b>ASISTENSI</b> en el momento de la <b>AFILIACIÓN </b>
              o en la <b>GENERACIÓN DEL LEAD</b> para que se materialice durante la <b>ACS</b>. El cliente no podrá aplicar dicho 5% de descuento junto con otro tipo de descuento. 
              En caso de que la <b>ACS</b> ocurra con dicho descuento, este descuento será restado del <b>PPL</b> total que recibe el <b>ALIADO</b> y el <b>ALIADO MAESTRO</b>.
            </p>


            <p className="my-4">
              <b>D. </b>El monto del <b>PPR</b> para el <b>ALIADO MAESTRO</b> será un <b>5%</b> del equivalente anual del precio de la póliza de <b>ASISTENSI </b> neto de descuentos. Si bien el <b>ALIADO </b>
              no recibirá <b>PPR</b>, el <b>ALIADO MAESTRO</b> podrá decidir si comparte parte de su <b>PPR</b> con el <b>ALIADO</b> en un acuerdo entre ellos en el que <b>ASISTENSI</b> no
               participará.
            </p>


            <p className="my-4">
              <b>E. </b>Los pagos de las <b>PPL</b> y las <b>PPR</b> se harán en ciclos mensuales bajo las siguientes condiciones:
            </p>

            <ul>
              <li>
                <b>I.</b> Los <b>PPL</b> que correspondan a <b>ACS</b> bajo modalidad anual y semestral: pago en el próximo <b>CICLO DE PAGO</b> del <b>100%</b> del <b>PPL</b> anual.
              </li>
              <li>
                <b>II.</b> Los <b>PPL</b> que correspondan a <b>ACS</b> bajo modalidad mensual y trimestral: pago del <b>100%</b> del <b>PPL</b> en el primer <b>CICLO DE PAGO</b> que ocurra
                 después de que transcurran los primeros tres meses calendarios desde la <b>ACS</b>.
              </li>

              <li>
                <b>III.</b> Los <b>PPR</b> se pagarán al <b>100%</b> en el próximo <b>CICLO DE PAGO</b> después de que se haga efectiva la respectiva renovación anual.
              </li>
            </ul>

            <p className="my-4">
              <b>F. </b> En el caso de que un cliente haga una cancelación del plan asociado a su <b>ACS</b>, sea voluntaria o por fallecimiento del beneficiario,
              aplicará la siguiente <b>política de descuentos de PPL y PPR por cancelaciones de clientes</b>:
            </p>

            <ul>
              <li>
                <b>I.</b> Durante el primer año calendario desde la nueva <b>ACS</b>:
                <li>
                  <ul>
                    <li>
                      <b>1.</b> 24 horas desde la emisión del pago por parte del cliente: <b>NO</b> genera <b>PPL</b>.
                    </li>
                    <li>
                      <b>2.</b> 24 horas a 90 días de la emisión del pago por parte del cliente: se aplicará un descuento del equivalente al <b>50%</b> del <b>PPL </b>
                      en el próximo <b>CICLO DE PAGO</b> al <b>ALIADO</b> y/o <b>ALIADO MAESTRO</b>.
                    </li>
                    <li>
                      <b>3.</b> Mayor a 91 días de la emisión del pago por parte del cliente: se aplicará un descuento del equivalente al <b>25%</b> del <b>PPL</b> en el
                      próximo <b>CICLO DE PAGO</b> al <b>ALIADO</b> y/o <b>ALIADO MAESTRO</b>.
                    </li>
                  </ul>
                </li>
              </li>
              <li>
                <b>II.</b> Después de los primeros 12 meses calendarios desde la nueva <b>ACS</b>:
                <li>
                  <ul>
                    <li>
                      <b>1.</b> 24 horas desde la emisión del pago: NO genera <b>PPR</b>.
                    </li>
                    <li>
                      <b>2.</b> Más de 24 horas de la emisión del pago: no se aplica descuento de <b>PPR</b> a <b>ALIADOS</b> y/o <b>ALIADOS MAESTROS </b> 
                      por cancelación.
                    </li>
                  </ul>
                </li>
              </li>

              <li>
                <b>III.</b> Cancelación por demostrarse que la información dada durante la afiliación fue inexacta, falsa o errada:: se aplicará un descuento del equivalente al <b>100%</b> del <b>PPL</b> o
                 <b> PPR</b> en el próximo <b>CICLO DE PAGO</b> al <b>ALIADO</b> y/o <b>ALIADO MAESTRO</b>.
              </li>

            </ul>

            <p className="my-4">
              <b>G. </b> Los desembolsos de los <b>PPL</b> y <b>PPR</b> se harán en dólares norteamericanos mediante PAYPAL o cualquier otro medio
              que <b>ASISTENSI</b> decida. El <b>ALIADO</b> y el <b>ALIADO MAESTRO</b> deberán, por cuenta propia, afiliarse a las plataformas de pago que sean
              necesarias para recibir dichos pagos y no podrán exigir a <b>ASISTENSI</b> el desembolso por otro medio distinto al que decida <b>ASISTENSI</b>.
                El <b>ALIADO</b> y el <b>ALIADO MAESTRO</b> se hacen responsables de las obligaciones fiscales que resulten de la recepción de dichos desembolsos
                 sobre las obligaciones tributarias que le sean aplicables y eximen a <b>ASISTENSI</b> de cualquier responsabilidad relacionada al incumplimiento
                  de dichas obligaciones fiscales.
            </p>

            <p className="my-4">
              <b>H. </b> En el caso de que el <b>ALIADO</b> o el <b>ALIADO MAESTRO</b> deje de cumplir las <b>CONDICIONES DE PERMANENCIA</b> descritas en el siguiente apartado,
               el mismo dejará de tener derecho a recibir nuevos <b>PPL</b> o <b>PRR</b> tanto para <b>ACS</b> pasadas como futuras más allá de aquellos que estén pendientes de
               pago y no hayan sido desembolsados por parte de <b>ASISTENSI</b>. En estos casos, el <b>ALIADO</b> y el <b>ALIADO MAESTRO</b> renuncian a exigir a <b>ASISTENSI </b>
               cualquier compensación incluyendo, pero no limitado a, <b>PPL</b> y <b>PPR</b> tanto para <b>ACS</b> pasadas como futuras.
            </p>


            <p className="m-t30">
              <b>TERCERO: CONDICIONES DE PERMANENCIA</b>
            </p>

            <p className="my-4">
              <b>A. </b> Se establece como <b>CUOTA DE PERMANENCIA</b> para el <b>ALIADO MAESTRO</b> el registro de al menos diez (10) nuevos <b>ACS</b> durante cada
              trimestre calendario asociados al total que resulte de sumar los nuevos <b>ACS </b> atribuibles a su <b>CÓDIGO DE ALIADO</b> más los nuevos <b>ACS</b>
              atribuibles a cada <b>ALIADO</b> cuyo <b>CÓDIGO DE ALIADO </b> esté clasificado bajo el <b>CÓDIGO DE ALIADO </b> del <b>ALIADO MAESTRO </b>. De no cumplir la <b>CUOTA
              DE PERMANENCIA</b>, se cancelará la condición de <b>ALIADO MAESTRO</b>. Dicha condición no aplicará a los primeros tres meses desde la autorización
               del <b>ALIADO MAESTRO</b>. <b>EL ALIADO</b> no tendrá <b>CUOTA DE PERMANENCIA</b>.
            </p>

            <p className="my-4">
              <b>B.  ACTIVIDADES NO PERMITIDAS.</b>  El <b>ALIADO</b> y el <b>ALIADO MAESTRO</b> no podrán intermediar entre <b>ASISTENSI</b> y persona alguna en la
              venta de los productos y servicios de <b>ASISTENSI</b>, recibir pagos por parte de clientes, o prestar asesoría de ningún tipo a persona
               alguna en materia de seguros o de los productos y servicios de <b>ASISTENSI</b>. Se excluyen los casos en que el <b>ALIADO</b> o el <b>ALIADO MAESTRO:
                (i)</b> tengan la autorizaciones pertinentes por parte de los reguladores aplicables para ejercer dicha intermediación o asesoría, y <b>(ii)</b>
                cuando el producto específico sobre el cual se intermedie o se preste asesoría no requiera que el <b>ALIADO</b> o el <b>ALIADO MAESTRO</b> esté
                autorizado por un regulador y el <b>ALIADO</b> o el <b>ALIADO MAESTRO</b> se encuentre presentes en una jurisdicción en la que dicha actividad no
                esté regulada. La realización por parte del <b>ALIADO</b> o el <b>ALIADO MAESTRO</b> de cualquiera de las <b>ACTIVIDADES NO PERMITIDAS</b> será causa suficiente
                 para la cancelación inmediata de la relación entre el <b>ALIADO</b> o el<b> ALIADO MAESTRO</b> y <b>ASISTENSI</b>. <b>ASISTENSI</b> no asumirá ninguna responsabilidad
                 por la realización de <b>ACTIVIDADES NO PERMITIDAS</b> por parte del ALIADO o el <b>ALIADO MAESTRO</b>.
            </p>

            <p className="my-4">
              <b>C. OTRAS CAUSAS DE TERMINACIÓN INMEDIATA.</b>  Este acuerdo terminará inmediatamente, y sin necesidad
              de aviso previo, si el <b>ALIADO</b> o el <b>ALIADO MAESTRO</b> y/o sus funcionarios, administradores, empleados o accionistas ejecutan una de las
               siguientes acciones:
            </p>

            <ul>
              <li>
                <b>I. </b> La ejecución de actos dolosos, fraudulentos, ilegales o deshonestos que afecten de una manera adversa a <b>ASISTENSI</b>.
              </li>
              <li>
                <b>II. </b> Alteración, falsificación o retención de cualquier información, sea escrita u oral, acerca de un solicitante o propuesto cliente.
              </li>
              <li>
                <b>III. </b> Cualquier alteración de la solicitud de contratación de los productos y servicios de <b>ASISTENSI</b> o la retención de cualquier
                 información requerida sobre la cual, si hubiere conocido <b>ASISTENSI</b>, esta hubiera sido razón suficiente para no emitir permitir la contratación
                 o hacerlo a un precio distinto.
              </li>
              <li>
                <b>IV. </b> El uso de la imagen de <b>ASISTENSI</b> y/o sus planes en campañas de publicidad sin la aprobación previa por parte de <b>ASISTENSI</b>.
              </li>
              <li>
                <b>V. </b> El pago a clientes, dinerario o en especie, en contraprestación por la contratación de los servicios y productos de <b>ASISTENSI</b>.
              </li>
              <li>
                <b>VI. </b> La representación ante cualquier persona, sociedad o entidad de una relación con <b>ASISTENSI</b> distinta a la descrita en el presente acuerdo.
              </li>
              <li>
                <b>VII. </b> Campañas o comunicaciones que desacrediten la actividad comercial de otro <b>ALIADO</b> o <b>ALIADO MAESTRO</b> ante propios o terceros.
              </li>

              <li>
                <b>VIII. </b>La disolución de la sociedad, o el fallecimiento de la persona, que tiene la condición de <b>ALIADO</b> o <b>ALIADO MAESTRO</b>.
              </li>
              <li>
                <b>IX. </b>La violación por parte del <b>ALIADO</b> o el <b>ALIADO MAESTRO</b> de las leyes, regulaciones, o reglamentos aplicables a este acuerdo.
              </li>
            </ul>


            <p className="my-4">
              <b>D. </b>  En el caso de terminación de este acuerdo o de la condición de <b>ALIADO</b> o de <b>ALIADO MAESTRO</b> por cualquier causa, el <b>ALIADO </b>
               y el <b>ALIADO MAESTRO</b> renunciará a cualquier <b>PPL</b> o <b>PPR</b> mas allá de aquellos que estén pendientes de pago y no hayan sido desembolsados
                por parte de <b>ASISTENSI</b>. Adicionalmente, en el caso de la terminación de este acuerdo o de la condición de <b>ALIADO MAESTRO</b> por cualquier
                causa, <b>ASISTENSI</b> se encargará de reclasificar unilateralmente el código de cada ALIADO que estaba clasificado bajo el código de dicho
                 <b> ALIADO MAESTRO</b> al código de un nuevo <b>ALIADO MAESTRO</b>.
            </p>


            <p className="m-t30">
              <b>CUARTO: CONFIDENCIALIDAD Y PROPIEDAD INTELECTUAL</b>
            </p>
            <p className="my-4">
              <b>A. </b>  El <b>ALIADO</b> y el <b>ALIADO MAESTRO</b> se comprometen a mantener bajo estricta confidencialidad cualquier sistema, herramienta, acuerdo o
               material de software entregado y licenciado a él por parte de <b>ASISTENSI</b>, así como a no divulgarlo, duplicarlo o reproducirlo, directa o
               indirectamente sin autorización. El <b>ALIADO</b> y el <b>ALIADO MAESTRO</b> adoptarán las medidas necesarias, dentro de lo razonable, para evitar que
                personas no autorizadas tengan acceso a los sistemas o material de software; así como para que aquellas autorizadas no los divulguen,
                dupliquen o reproduzcan. El <b>ALIADO</b> y el <b>ALIADO MAESTRO</b> serán legalmente responsables de los daños y perjuicios que pudieren ocasionarse
                por la inobservancia de los compromisos anteriormente señalados. <b>ASISTENSI</b> podrá cesar en cualquier momento el licenciamiento
                de los sistemas y materiales al <b>ALIADO</b> y el<b> ALIADO MAESTRO</b>. A la terminación de este acuerdo, el <b>ALIADO</b> y el <b>ALIADO MAESTRO</b> suspenderá
                definitivamente el uso de los sistemas, herramientas y material de software y los eliminará de sus archivos (p.ej., discos duros, unidades
                 de seguridad, etc.).
            </p>

            <p className="my-4">
              <b>B. </b>  Es entendido que los logos, lemas, colores de marca, sellos distintivos, certificados de calidad, software, certificaciones
               gremiales, principales logros y/o características diferenciadoras de los productos son propiedad exclusiva de <b>ASISTENSI</b>. El derecho de
                <b> ALIADO</b> y el <b> ALIADO MAESTRO</b> de usar dichos elementos se limitará, al logro del objeto del presente acuerdo.
            </p>

            <p className="my-4">
              <b>C. </b>  El <b>ALIADO</b> y el <b>ALIADO MAESTRO</b> reconocen que, en ejecución del presente acuerdo, recibirán información de naturaleza privilegiada
               por parte de <b>ASISTENSI</b>. Por ello, declaran que están conscientes de la naturaleza confidencial y el valor comercial y competitivo de la
               Información Confidencial que se transmitirán y el daño que pudiera resultar para <b>ASISTENSI</b> si ésta es revelada a cualquier persona en
               violación de este acuerdo. Por ello el <b>ALIADO</b> y el <b>ALIADO MAESTRO</b> se obliga a mantener la Información Confidencial que reciba de <b>ASISTENSI</b>,
               antes o después de la aceptación del presente acuerdo, en la más estricta confidencialidad y reserva, así como a no transmitir, transferir,
                divulgar, copiar, revelar, o por cualquier medio dar a conocer la Información Confidencial a terceros. El <b>ALIADO</b> y el <b>ALIADO MAESTRO</b> serán
                legalmente responsable de los daños y perjuicios que pudieren ocasionarse por la inobservancia de los compromisos anteriormente señalados.
            </p>

            <p className="my-4">
              <b>D. </b> Las obligaciones del <b>ALIADO</b> y el <b>ALIADO MAESTRO</b> en relación con la Información Confidencial sobrevivirán desde la
              terminación de este acuerdo por dos (2) años.
            </p>


            <p className="m-t30">
              <b>QUINTO: ACTUALIZACIONES, CAMBIOS Y AJUSTES</b>
            </p>

            <p className="my-4">
              <b>A. </b> Es competencia exclusiva de <b>ASISTENSI</b> modificar unilateralmente cualquier parte del presente acuerdo incluyendo,
              pero no limitado a, los montos y condiciones de los <b>PPL</b> y <b>PPR</b> y las <b>CONDICIONES DE PERMANENCIA</b>. Para hacerlo, sólo deberá
              notificar al <b>ALIADO</b> o al <b>ALIADO MAESTRO</b> con un mes de antelación de la entrada en vigor de dichos cambios.
            </p>

            <p className="my-4">
              <b>B. </b> En estos casos, el <b>ALIADO</b> y el <b>ALIADO MAESTRO</b> renuncian a exigir a <b>ASISTENSI</b> cualquier compensación por cualquier cambio
              realizado unilateralmente por <b>ASISTENSI</b> incluyendo, pero no limitado a, <b>PPL</b> y <b>PPR</b> tanto para <b>ACS</b> pasadas como futuras.
            </p>

            <p className="m-t30">
              <b>SEXTO: OTROS TEMAS DE RELEVANCIA</b>
            </p>


            <p className="my-4">
              <b>A. </b> Es competencia exclusiva de <b>ASISTENSI</b>, definir los montos y condiciones de los productos y servicios de <b>ASISTENSI</b>.
            </p>
            <p className="my-4">
              <b>B. </b> En la medida requerida por la ley, el <b>ALIADO</b> y el <b>ALIADO MAESTRO</b> se hacen responsables y asumen cualquier obligación de
              cumplir con las leyes que estén relacionadas a la relación descrita en este acuerdo de <b>(i)</b> las jurisdicciones donde el <b>ALIADO</b> o el
              <b> ALIADO MAESTRO</b> residen, y <b>(ii)</b>  las jurisdicciones de Barbados, E.E.U.U y cualquier otro país que imponga obligaciones relacionadas
              a la relación descrita en este acuerdo como por ejemplo pero no limitado a OFAC, HIPAA, U.S. Foreign Corrupt Practices Act y leyes
               relacionadas a evitar el lavado de dinero.
            </p>

            <p className="my-4">
              <b>C. </b>El <b>ALIADO</b> y el <b>ALIADO MAESTRO</b> eximen, y o indemnizarán, a <b>ASISTENSI</b> de cualquier costo o daño que resulte del
               incumplimiento del presente acuerdo.
            </p>
            <p className="my-4">
              <b>D. </b>La responsabilidad de <b>ASISTENSI</b> ante cualquier daño relacionado al presente acuerdo estará limitada al total de <b>PPL</b> y <b>PPR </b>
               desembolsados al <b>ALIADO</b> o <b>ALIADO MAESTRO</b>, neto de descuentos por cancelaciones, durante los últimos 12 meses precedentes a la
               presentación de la disputa. El <b>ALIADO</b> y el <b>ALIADO MAESTRO</b> aceptan que no podrán reclamar compensación de daño alguno después
               que hayan ocurrido 12 meses desde que dicho daño haya ocurrido.
            </p>

            <p className="my-4">
              <b>E. </b> EL <b>ALIADO</b> y el <b>ALIADO MAESTRO</b> prestarán el servicio objeto del presente acuerdo con sus propios medios y personal.
              Queda entendido que <b>ASISTENSI</b> no tendrá ninguna responsabilidad de tipo laboral con las personas que presten sus servicios bajo
              relación de dependencia, dirección y supervisión de <b>ALIADO</b> y el <b>ALIADO MAESTRO</b>; en consecuencia, será por única y exclusiva cuenta
               de <b>ALIADO</b> y el <b>ALIADO MAESTRO</b> el cumplimiento de todas las obligaciones que, para sus trabajadores, pueda imponerle la legislación
               laboral vigente.
            </p>

            <p className="my-4">
              <b>F. </b> La relación entre <b>ALIADO</b> y el <b>ALIADO MAESTRO</b> no es exclusiva, y en tal virtud <b>ASISTENSI</b> se reserva el derecho de contratar
               con otros agentes para la gestión objeto del presente acuerdo.
            </p>

            <p className="my-4">
              <b>G. </b> El presente acuerdo tendrá vigencia por un (1) año, contado a partir de su aceptación por parte del <b>ALIADO</b> o el
              <b> ALIADO MAESTRO</b>. El mismo se considerará renovado automáticamente, a menos que una de las partes no notifique por escrito a
              la otra, su voluntad de no renovarlo con una anticipación de por lo menos un (1) mes.
            </p>

            <p className="my-4">
              <b>H. </b> Para todos los efectos del presente acuerdo se usarán las leyes de Barbados como la legislación aplicable y la
              ciudad de Bridgetown como el domicilio especial y excluyente de cualquier otro.
            </p>

            <p className="my-4">
              <b>I. </b> El <b>ALIADO</b> o el <b>ALIADO MAESTRO </b> no podrá ceder o traspasar en forma alguna el presente acuerdo a terceras personas o
              sociedades.
            </p>

            <p className="my-4">
              <b>J. </b> Cualquier notificación, aviso, requerimiento o cualquier otra comunicación por parte de <b>ASISTENSI</b> a ser entregada
              o a realizarse de conformidad con el presente acuerdo al <b>ALIADO</b> o el <b>ALIADO MAESTRO</b> deberá ser por defecto al correo electrónico
               suministrado por el <b>ALIADO</b> o el <b>ALIADO MAESTRO</b> en el momento de su alta, sin necesidad de acuse de recibo, y surtirá efectos al
               momento de su envío. En el caso de las comunicaciones por parte del <b>ALIADO</b> o el <b>ALIADO MAESTRO</b> hacia <b>ASISTENSI</b> se harán a la dirección
                de correo habilitada por <b>ASISTENSI</b> para sus aliados y requerirá acuse de recibo.
            </p>

            <p className="my-4">
              <b>K. </b> Este documento constituye el acuerdo total entre el <b>ALIADO</b> o el <b>ALIADO MAESTRO</b> y <b>ASISTENSI</b> en relación con el objeto de este
               acuerdo y no existen otras declaraciones, acuerdos o convenios relacionados con este acuerdo que no se encuentre claramente expresados
                en este instrumento y en sus anexos. Las declaraciones y cláusulas que se contienen en este documento forman parte integral del acuerdo
                 de voluntades entre las entre el <b>ALIADO</b> o el <b>ALIADO MAESTRO</b> y <b>ASISTENSI</b> y deben ser tomados en cuenta por la interpretación de este acuerdo.
            </p>
            <p className="my-4">
              <b>L. </b> La nulidad o imposibilidad de ejercer cualquier provisión contenida en el presente acuerdo no afectará la validez o la capacidad de ejercer 
              cualquier otra provisión contenida en el mismo.
            </p>
          </div>

        </div>
      </div>
    );
  }
}

TermsConditionAlly.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withMobileDialog({ breakpoint: "xs" })(
  withStyles(styles)(TermsConditionAlly)
);
